import React from "react"
import {
  IoIosFlash,
  IoIosCalendar,
  IoIosAperture,
  IoIosFiling,
  IoIosCog,
  IoIosEye,
  IoIosWallet,
  IoIosSend,
  IoIosVideocam,
} from "react-icons/io"

const colorsDict = {
  greyLighter: "#F1F5F8",
  greyDark: "#8795A1",
  yellowLighter: "#FFF9C2",
  yellowDark: "#F2D024",
  greenLighter: "#A2F5BF",
  greenDark: "#1F9D55",
  tealDark: "#38A89D",
  tealLighter: "#A0F0ED",
  indigoDark: "#5661B3",
  indigoLighter: "#B2B7FF",
  blueDark: "#2779BD",
  blueLighter: "#BCDEFA",
  redDark: "#CC1F1A",
  redLighter: "#F9ACAA",
  orangeDark: "#DE751F",
  orangeLighter: "#FCD9B6",
}

const badgeStyles = {
  margin: "0px 0px 30px",
  width: "60px",
  height: "60px",
  borderRadius: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}

const FeatureBadge = ({ slug }) => {
  let colors, icon
  switch (slug) {
    case "automation":
      colors = {
        background: colorsDict.yellowLighter,
        color: colorsDict.yellowDark,
      }
      icon = <IoIosFlash size="30px" />
      break
    case "billing":
      colors = {
        background: colorsDict.blueLighter,
        color: colorsDict.blueDark,
      }
      icon = <IoIosSend size="30px" />
      break

    case "compose-letters":
      colors = {
        background: colorsDict.blueLighter,
        color: colorsDict.blueDark,
      }
      icon = <IoIosFiling size="30px" />
      break
    case "practice-management":
      colors = {
        background: colorsDict.tealLighter,
        color: colorsDict.tealDark,
      }
      icon = <IoIosCog size="30px" />
      break
    case "revenue-enhancement":
      colors = {
        background: colorsDict.greyLighter,
        color: colorsDict.greyDark,
      }
      icon = <IoIosEye size="30px" />
      break
    case "online-payment":
      colors = {
        background: colorsDict.greenLighter,
        color: colorsDict.greenDark,
      }
      icon = <IoIosWallet size="30px" />
      break
    case "online-booking":
      colors = {
        background: colorsDict.redLighter,
        color: colorsDict.redDark,
      }
      icon = <IoIosCalendar size="30px" />
      break
    case "patient-portal":
      colors = {
        background: colorsDict.indigoLighter,
        color: colorsDict.indigoDark,
      }
      icon = <IoIosAperture size="30px" />
      break
    case "video-calls":
      colors = {
        background: colorsDict.blueDark,
        color: "white",
      }
      icon = <IoIosVideocam size="30px" />
      break
    default:
      colors = {
        background: colorsDict.greenLighter,
        color: colorsDict.greenDark,
      }
      icon = <IoIosFlash size="30px" />
  }
  return (
    <div
      style={{
        ...badgeStyles,
        ...colors,
      }}
    >
      {icon}
    </div>
  )
}

export default FeatureBadge
