import React from "react"
import { Link } from "gatsby"
import { Container, Row, Col, Badge, Button } from "reactstrap"

import Layout from "src/components/Layout"
import Seo from "src/components/Seo"
import Heading from "src/components/index/Heading"
import StyledIllustration from "src/components/features/StyledIllustration.jsx"
import TestimonialCard from "src/components/TestimonialCard"
import ViewMoreFeatures from "src/components/features/ViewMoreFeatures"

import secretaryAvatar from "src/assets/img/testimonials/secretary-small-square.jpg"
import inviteIllustration from "src/assets/img/icons/undraw/invite-gradient.svg"
import chooseIllustration from "src/assets/img/icons/undraw/choose.svg"
import controlPanelIllustration from "src/assets/img/icons/undraw/control-panel.svg"
import eventsIllustration from "src/assets/img/icons/undraw/events.svg"
import filesIllustration from "src/assets/img/icons/undraw/files-gradient.svg"
import alertIllustration from "src/assets/img/icons/undraw/alert-gradient.svg"

const FeaturePracticeManagement = () => {
  return (
    <Layout>
      <Seo
        title="Carebit: Features | Practice management"
        pathname="/features/practice-management"
        description="Excellent practice management software is at the heart of a well-run clinic. Carebit is thoughtfully-designed, easy to use and incredibly powerful."
      />
      <div className="u-centerFlex u-textCenter Hero">
        <Container>
          <Row>
            <Col
              lg={{ size: 6, offset: 3 }}
              md={{ size: 10, offset: 1 }}
              style={{ zIndex: 2, padding: "120px 40px 200px", color: "white" }}
            >
              <Badge color="info" className="u-marginBottom20">
                Features
              </Badge>
              <h1>Practice management</h1>
              <p className="u-whiteTranslucent">
                Excellent practice management software is at the heart of a
                well-run clinic. Easy to use and incredibly powerful, Carebit
                has all the features you thought you needed and even more that
                you didn’t.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
      <div>
        <Container>
          <Row>
            <Col md={{ size: 10, offset: 1 }}>
              <section>
                <Row className="u-centerFlex">
                  <Col lg={6} md={12} className="SplitContentRow-text">
                    <Row>
                      <Heading title="Safely stored">
                        Store and search patient information
                      </Heading>
                    </Row>
                    <p className="u-midGrey">
                      Store core patient and GP data securely in Carebit’s
                      encrypted database, and upload notes, letters and test
                      results too. Search is lightning-fast, so you’re never
                      left waiting with a patient on the phone.
                    </p>
                  </Col>
                  <Col lg={6} md={12} className="SplitContentRow-graphic">
                    <StyledIllustration src={inviteIllustration} />
                  </Col>
                </Row>
              </section>

              <section>
                <Row className="u-centerFlex">
                  <Col lg={6} md={12} className="SplitContentRow-graphic">
                    <StyledIllustration src={chooseIllustration} />
                  </Col>
                  <Col lg={6} md={12} className="SplitContentRow-text">
                    <Row>
                      <Heading title="Goodbye clinic list">
                        View each doctor’s agenda
                      </Heading>
                    </Row>
                    <p className="u-midGrey">
                      Each doctor in Carebit has their own agenda view, so you
                      can quickly see what appointments they have on a specific
                      day - neatly replacing the printed clinic list. You can
                      even click an empty slot to book a patient in.
                    </p>
                  </Col>
                </Row>
              </section>

              <section>
                <Row className="u-centerFlex">
                  <Col lg={6} md={12} className="SplitContentRow-text">
                    <Row>
                      <Heading title="Never forget">
                        Set reminders for followups
                      </Heading>
                    </Row>
                    <p className="u-midGrey">
                      Create reminders in Carebit for when a patient needs to be
                      contacted for their next appointment, or for any other
                      task. You’ll receive an email to remind you and a
                      notification in Carebit.
                    </p>
                  </Col>
                  <Col lg={6} md={12} className="SplitContentRow-graphic">
                    <StyledIllustration src={alertIllustration} />
                  </Col>
                </Row>
              </section>

              <section>
                <Row className="u-centerFlex">
                  <Col lg={6} md={12} className="SplitContentRow-graphic">
                    <StyledIllustration src={eventsIllustration} />
                  </Col>
                  <Col lg={6} md={12} className="SplitContentRow-text">
                    <Row>
                      <Heading title="Here, there and everywhere">
                        Control doctor availability
                      </Heading>
                    </Row>
                    <p className="u-midGrey">
                      We know from experience how complex doctors’ schedules can
                      be. That’s why Carebit includes a powerful availability
                      tool that lets you set the hours, days, services and
                      locations that your clinicians are working. You can even
                      set recurring options. Changes you make are reflected
                      instantly for patients making online bookings, too.
                    </p>

                    <div style={{ marginTop: "40px" }}>
                      <Link to="/features/online-booking">
                        <Button color="secondary" size="md">
                          Learn more about online bookings &rarr;
                        </Button>
                      </Link>
                    </div>
                  </Col>
                </Row>
              </section>

              <section>
                <Row className="u-centerFlex">
                  <Col lg={6} md={12} className="SplitContentRow-text">
                    <Row>
                      <Heading title="Optimise billings">
                        Manage invoices and receive online payments
                      </Heading>
                    </Row>
                    <p className="u-midGrey">
                      Carebit includes a number of helpful features for you to
                      keep on top of your billings. See a patient’s invoices at
                      a glance, mark invoices as paid, record invoice payments
                      and set up automation for hands-free generation of
                      invoices and invoice reminders. And if a patient pays
                      their invoice online with Carebit, their invoice is
                      automatically updated with no further work required.
                    </p>
                  </Col>
                  <Col lg={6} md={12} className="SplitContentRow-graphic">
                    <StyledIllustration src={filesIllustration} />
                  </Col>
                </Row>
              </section>

              <section>
                <Row className="u-centerFlex">
                  <Col lg={6} md={12} className="SplitContentRow-graphic">
                    <StyledIllustration src={controlPanelIllustration} />
                  </Col>
                  <Col lg={6} md={12} className="SplitContentRow-text">
                    <Row>
                      <Heading title="Save time and money">
                        Set up and configure automation
                      </Heading>
                    </Row>
                    <p className="u-midGrey">
                      Carebit lets you configure Workflows, which are tasks that
                      run automatically and save huge amounts of secretarial
                      time. For example, you can configure Carebit to generate
                      and email letters to patients, or automatically issue
                      invoices instead of having to do it manually. It’s fast,
                      accurate and works 24/7. Best of all, it’s included in the
                      cost of your Carebit subscription.
                    </p>

                    <div style={{ marginTop: "40px" }}>
                      <Link to="/features/automation">
                        <Button color="secondary" size="md">
                          Learn more about automation features &rarr;
                        </Button>
                      </Link>
                    </div>

                    <div style={{ marginTop: "20px" }}>
                      <Link to="/case-studies/santis-automating-secretarial-tasks">
                        <Button color="light" size="md">
                          Read the case study on automating secretarial tasks
                          &rarr;
                        </Button>
                      </Link>
                    </div>
                  </Col>
                </Row>
              </section>
            </Col>
          </Row>
        </Container>

        <div className="Index-gradient">
          <div className="ColouredDivider Index-dividerBlueGreen">
            <Container>
              <section>
                <TestimonialCard
                  author="Mrs Simone Castle"
                  authorSubtext="Secretary, Santis"
                  avatarSrc={secretaryAvatar}
                >
                  <p>
                    “Over the years I have worked on many patient medical
                    systems which have been complicated, difficult to understand
                    and unable to give me the information I really wanted. Since
                    using Carebit, I have found it to be user friendly, simple
                    in obtaining documents I require and easy to input required
                    patient documentation.
                  </p>
                  <p>
                    Using Carebit you are able to see all the patient
                    information at a glance and it has enabled me to become more
                    efficient in the office. I am able to see the latest details
                    of the patient, results and correspondence in date order,
                    and all invoicing clearly. Any technical issues I have have
                    been sorted quickly and efficiently by the Carebit Customer
                    Services team and any additions that I have required to the
                    package have been added promptly.
                  </p>
                  <p>
                    I would recommend any medical secretary/PA or medical
                    practice to use Carebit. I have been able to teach new staff
                    how to use Carebit within a matter of hours, as opposed to
                    days that I used to have to spend to try and work out other
                    systems.”
                  </p>
                </TestimonialCard>
              </section>
            </Container>
          </div>
        </div>

        <ViewMoreFeatures />
      </div>
    </Layout>
  )
}

export default FeaturePracticeManagement
