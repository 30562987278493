import React from "react"
import { Container, Row, Col } from "reactstrap"
import { Link } from "gatsby"

import FeatureBadge from "./FeatureBadge"

const featurePages = [
  {
    slug: "compose-letters",
    title: "Compose letters",
    description:
      "Create patient letters quickly using letter templates and Carebit’s Compose feature, and send them securely and instantly to clinics anywhere in the world.",
  },
  {
    slug: "automation",
    title: "Automation",
    description:
      "Use Carebit’s sophisticated automation engine to execute tasks like generating letters and emails in response to events like bookings and invoices.",
  },
  {
    slug: "patient-portal",
    title: "Patient portal",
    description:
      "Patients can log in to the secure Carebit portal to access letters and test results at their convenience. No more ringing up your secretary to ask for copies.",
  },
  {
    slug: "online-booking",
    title: "Online booking",
    description:
      "Patients can book and pay for appointments online from anywhere in the world, 24/7. Your practice just went global.",
  },
  {
    slug: "practice-management",
    title: "Practice management",
    description:
      "Powerful practice management features are included as standard. Register patients, generate clinic/theatre lists, make bookings and much more.",
  },
  {
    slug: "billing",
    title: "Billing",
    description:
      "Send invoices electronically to insurance companies through Healthcode, easily record remittance payments and swiftly issue shortfall/excess invoices.",
  },
  {
    slug: "online-payment",
    title: "Online payment",
    description:
      "Carebit includes the ability for patients to pay their invoices online by card. Receipts are issued automatically too, saving your practice time.",
  },
  {
    slug: "revenue-enhancement",
    title: "Revenue enhancement",
    description:
      "Say goodbye to bad debts with Carebit’s automated patient invoice reminder system.",
  },
]

/**
 * ViewMoreFeatures - a presentational Component that displays links to other
 * feature pages (excluding the feature page that this Component is displayed on.)
 */
const ViewMoreFeatures = () => {
  // Filter out the current page that this Component is displayed on.
  let featurePagesToRender = featurePages.filter(page => {
    if (typeof window !== "undefined") {
      // window may be undefined at buildtime.
      return page.slug !== window.location.pathname.split("/").pop()
    } else {
      return true
    }
  })
  return (
    <Container>
      <section>
        <Row style={{ marginBottom: "40px" }}>
          <Col md={{ size: 10, offset: 1 }}>
            <h2>Discover all Carebit features</h2>
          </Col>
        </Row>
        <Row>
          <Col md={{ size: 10, offset: 1 }}>
            <Row>
              {featurePagesToRender.map(page => (
                <Col lg={4} md={12} key={page.slug} className="Card">
                  <Link to={`/features/${page.slug}`}>
                    <FeatureBadge slug={page.slug} />
                    <h5>{page.title}</h5>
                    <p className="u-midGrey u-marginBottom0">
                      {page.description}
                    </p>
                  </Link>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </section>
    </Container>
  )
}

export default ViewMoreFeatures
